import React, { PropsWithChildren } from 'react'
import {
  AccordionItem as MantineAccordionItem,
  AccordionItemProps,
} from '@mantine/core'

import { ForbidClassNamesProp } from '@/core/mantine-ui/types'

import styles from './AccordionItem.module.scss'

export const AccordionItem = ({
  children,
  ...restProps
}: PropsWithChildren<ForbidClassNamesProp<AccordionItemProps>>) => {
  return (
    <MantineAccordionItem {...restProps} classNames={{ item: styles.item }}>
      {children}
    </MantineAccordionItem>
  )
}
