import { z } from 'zod'

const documentClaimServerMessageSchema = z.object({
  event_type: z.literal('user_document_claim'),
  data: z.object({
    claimed_by: z.string(),
    document_uuid: z.string().uuid(),
  }),
})

const documentTaskStatusSchema = z.object({
  task_status: z.enum([
    'success',
    'in_progress',
    'failed',
    'no_data',
    'not_started',
  ]),
  document_uuid: z.string().uuid(),
})

const documentTaskStatusServerMessageSchema = z.object({
  event_type: z.literal('document_task_status'),
  data: documentTaskStatusSchema,
})

const documentsServerWSMessagesSchema = z.union([
  documentClaimServerMessageSchema,
  documentTaskStatusServerMessageSchema,
])

const documentsServerWSEvent = z.object({
  ws_type: z.literal('documents'),
  message: documentsServerWSMessagesSchema,
})

export type DocumentsServerWSEvent = z.infer<typeof documentsServerWSEvent>

export type DocumentClaimServerMessage = z.infer<
  typeof documentClaimServerMessageSchema
>

export type DocumentTaskStatus = z.infer<typeof documentTaskStatusSchema>
