import { ErrorMonitoring } from '@/core/services/error-monitoring'

type NewSession = {
  clientId: string
  formattedData: {
    data: string
    end_time: string
    id: string
    place_of_service_id: string
    session_type: string
    staff: string
    start_time: string
  }
}

let isFetching = false

export const createNewOfflineSession = async (newSessionData: string) => {
  if (isFetching) {
    return
  }

  isFetching = true
  try {
    const { clientId, formattedData: payload } = JSON.parse(
      newSessionData,
    ) as NewSession
    const response = await fetch(
      new URL(
        `${window.location.origin}/api/offline/client/${clientId}/new_session/`,
      ),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': window.CSRFTOKEN,
          Accept: 'application/json',
        },
        body: JSON.stringify(payload),
      },
    )

    if (response.ok) {
      localStorage.removeItem('new-session-to-post')
    } else {
      throw new Error(`failed to create a new offline session`)
    }
  } catch (e) {
    if (e instanceof Error) {
      const error = new Error(e.message, { cause: e })
      error.name = `Error during session sync: ${error.name}`

      ErrorMonitoring.captureException(error)
    }
  } finally {
    isFetching = false
  }
}
