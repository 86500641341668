import { useCallback } from 'react'

import { notifications } from '@/core/services/notifications'

import {
  BulkUpdateNotificationsServerMessage,
  NotificationListServerMessage,
  NotificationsCountServerMessage,
  Notification,
  PushNotificationServerMessage,
} from '../schemas'

interface NotificationEventsProps {
  getNotificationsCount: () => void
  setNotificationsCountState: (count: number) => void
  setNotificationsListState: (notifications: Notification[]) => void
}

export const useNotificationEventHandlers = ({
  getNotificationsCount,

  setNotificationsCountState,
  setNotificationsListState,
}: NotificationEventsProps) => {
  const onConnect = useCallback(() => {
    getNotificationsCount()
  }, [getNotificationsCount])

  const onCountChange = useCallback(
    ({ data: { count } }: NotificationsCountServerMessage) => {
      setNotificationsCountState(count)
    },
    [setNotificationsCountState],
  )

  const onNotificationsListChange = useCallback(
    ({ data: { notifications } }: NotificationListServerMessage) => {
      setNotificationsListState(notifications)
    },
    [setNotificationsListState],
  )

  const onPushNotificationChange = useCallback(
    ({ data: { notification } }: PushNotificationServerMessage) => {
      notifications.show(notification)
    },
    [],
  )

  const onBulkUpdateNotificationsChange = useCallback(
    ({
      data: { notifications, count },
    }: BulkUpdateNotificationsServerMessage) => {
      setNotificationsCountState(count)
      setNotificationsListState(notifications)
    },
    [setNotificationsCountState, setNotificationsListState],
  )

  return {
    onConnect,
    onCountChange,
    onNotificationsListChange,
    onPushNotificationChange,
    onBulkUpdateNotificationsChange,
  }
}
