import React from 'react'

import { PushNotification } from '@/core/services/websocket/services'

interface NotificationDescriptionProps {
  description: PushNotification['description']
  actionLink: PushNotification['action_link']
}

export const NotificationDescription = ({
  description,
  actionLink,
}: NotificationDescriptionProps) => {
  if (!actionLink)
    return (
      <div className="max-h-156 overflow-auto scrollbar-thin">
        {description}
      </div>
    )

  return (
    <div>
      <p className="mb-4">{description}</p>

      <a
        href={actionLink.url}
        target="__blank"
        className="font-semibold underline text-primary"
      >
        {actionLink.label}
      </a>
    </div>
  )
}
