import React, { PropsWithChildren } from 'react'
import {
  AccordionControl as MantineAccordionControls,
  AccordionControlProps,
} from '@mantine/core'

import { ForbidClassNamesProp } from '@/core/mantine-ui/types'

import styles from './AccordionControl.module.scss'

export const AccordionControl = ({
  children,
  ...restProps
}: PropsWithChildren<ForbidClassNamesProp<AccordionControlProps>>) => {
  return (
    <MantineAccordionControls
      {...restProps}
      classNames={{ control: styles.control }}
    >
      {children}
    </MantineAccordionControls>
  )
}
