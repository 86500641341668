// dbw 3/13/25: GROUPED_PROGRAM_TARGET_STATUSES is derived from PROGRAM_TARGET_STATUSES, but with all "maintenance"
// statuses consolidated into a single status. Make sure if you add a new target status to PROGRAM_TARGET_STATUSES
// here that you add them to GROUPED_PROGRAM_TARGET_STATUSES.
export const PROGRAM_TARGET_STATUSES = [
  'not_started',
  'baseline',
  'intervention',
  'maintenance_daily',
  'maintenance_eod',
  'maintenance_weekly',
  'maintenance_every_other_week',
  'maintenance_monthly',
  'on_hold',
  'completed',
  'archived',
] as const

export const GROUPED_PROGRAM_TARGET_STATUSES = [
  'not_started',
  'baseline',
  'intervention',
  'maintenance',
  'on_hold',
  'completed',
  'archived',
] as const

export const MAINTENANCE_STATUSES: [
  'maintenance_daily',
  'maintenance_eod',
  'maintenance_weekly',
  'maintenance_every_other_week',
  'maintenance_monthly',
] = [
  'maintenance_daily',
  'maintenance_eod',
  'maintenance_weekly',
  'maintenance_every_other_week',
  'maintenance_monthly',
]

export type MaintananceStatuses = (typeof MAINTENANCE_STATUSES)[number]
export type TargetStatus = (typeof PROGRAM_TARGET_STATUSES)[number]
export type GroupedTargetStatus =
  (typeof GROUPED_PROGRAM_TARGET_STATUSES)[number]
