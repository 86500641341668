import React, { PropsWithChildren } from 'react'
import {
  AccordionPanel as MantineAccordionPanel,
  AccordionPanelProps,
} from '@mantine/core'

import { ForbidClassNamesProp } from '@/core/mantine-ui/types'

import styles from './AccordionPanel.module.scss'

export const AccordionPanel = ({
  children,
  ...restProps
}: PropsWithChildren<ForbidClassNamesProp<AccordionPanelProps>>) => {
  return (
    <MantineAccordionPanel {...restProps} classNames={{ panel: styles.panel }}>
      {children}
    </MantineAccordionPanel>
  )
}
