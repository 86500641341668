import React from 'react'
import {
  SelectProps,
  Select as MantineSelect,
  ComboboxItem,
} from '@mantine/core'

export const Select = (props: SelectProps) => {
  return <MantineSelect {...props} />
}

export type { SelectProps as MantineSelectProps }
export type { ComboboxItem as MantineComboboxItem }
