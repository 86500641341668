import React, {
  createContext,
  ReactNode,
  use,
  useState,
  useEffect,
} from 'react'

import { OfflineApplication } from '../OfflineApplication'

const offlineApplication = new OfflineApplication()
const OfflineApplicationContext = createContext({
  initializing: true,
  offlineApplication,
})

const OfflineApplicationProvider = ({ children }: { children: ReactNode }) => {
  const [initializing, setInitializing] = useState(false)

  const initializeOfflineApplication = async () => {
    setInitializing(true)
    try {
      await offlineApplication.init()
    } catch (err) {
      console.error('Error initializing offline application', err)
    } finally {
      setInitializing(false)
    }
  }

  useEffect(() => {
    initializeOfflineApplication()
  }, [])

  return (
    <OfflineApplicationContext value={{ initializing, offlineApplication }}>
      {children}
    </OfflineApplicationContext>
  )
}

const useOfflineApplication = () => {
  const context = use(OfflineApplicationContext)
  if (!context) {
    throw new Error(
      'useOfflineApplication must be used within an OfflineApplicationProvider',
    )
  }
  return context
}

export { OfflineApplicationProvider, useOfflineApplication }
