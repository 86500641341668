import React, { PropsWithChildren } from 'react'
import {
  Accordion as MantineAccordion,
  AccordionProps as MantineAccordionProps,
} from '@mantine/core'
import cx from 'classnames'

import { ForbidClassNamesProp } from '@/core/mantine-ui/types'

import styles from './Accordion.module.scss'

interface AccordionProps
  extends PropsWithChildren<
    ForbidClassNamesProp<MantineAccordionProps<boolean>>
  > {
  size?: 'sm' | 'md'
  activeControlColor?: 'default' | 'primary'
}

export const Accordion = ({
  children,
  size = 'md',
  activeControlColor = 'primary',
  ...restProps
}: AccordionProps) => {
  return (
    <MantineAccordion
      data-testid="accordion-root"
      {...restProps}
      classNames={{
        root: styles.root,
        chevron: styles.chevron,
        content: styles.content,
        control: cx(
          { [styles.sizeSm]: size === 'sm' },
          { [styles.sizeMd]: size === 'md' },
          { [styles.controlDefaultColor]: activeControlColor === 'primary' },
        ),
      }}
    >
      {children}
    </MantineAccordion>
  )
}
