import { TWYLL_OFFLINE } from '@/offline/managers/constant'

import { config } from './config'
import { DefaultLocalDataManager } from './managers/DefaultLocalDataManager'
import SymmetricKeyManager from './managers/SymmetricKeyManager'
import { encryptSymmetrically } from './services/DefaultCryptographyService'

export const iseExistsInCache = async () => {
  // check if sessions tables aren't empty
  const joinLink = document
    .querySelector('a[data-testid="sessionIdentityButton"]')
    ?.getAttribute('href')

  if (!joinLink) {
    // empty sessions list so we have everything cached
    return true
  }

  const cache = await caches.open(TWYLL_OFFLINE)
  const keys = await cache.keys()

  return keys?.some((request) => request.url.includes(joinLink))
}

export const removeNullSegments = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      removeNullSegments(obj[key])
    }
  }

  return obj
}

export const saveEncryptedDataToCache = async (
  data: any,
  cachePath: string,
) => {
  try {
    const ldm = new DefaultLocalDataManager(config.DB_NAME, config.DB_VERSION)
    const symmetricKeyManager = new SymmetricKeyManager(ldm)
    const dataJSON = JSON.stringify(data)
    const key = await symmetricKeyManager.get()
    const encryptedData = await encryptSymmetrically(key, dataJSON)
    const headers = {
      'Content-Type': 'text/plain',
      'Content-Length': encryptedData.length.toString(),
      'Response-Type': 'basic',
      Vary: 'Accept-Language, Cookie',
    }
    const dataResponse = new Response(encryptedData, {
      status: 200,
      headers,
    })
    const cacheTwyllOffline = await caches.open(TWYLL_OFFLINE)

    await cacheTwyllOffline.put(cachePath, dataResponse)
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : e
    throw new Error(`Failed to save encrypted data: ${errorMessage}`, {
      cause: e,
    })
  }
}
