/**
 * This module is used to initialize global services that must always be
 * initialized before other application modules.
 */

// dbw 3/6/25: pdfjs has a call to Promise.withResolvers, but our vendors file isn't processed by Babel,
// which is how we load our polyfills, so we explicitly add the polyfill here. We don't support any
// browsers currently that don't have Promise.withResolvers, but some users are using unsupported browsers
// so once they come into compliance with the policy, we can remove this import.
import 'core-js/actual/promise/with-resolvers'

import { pdfjs } from 'react-pdf'

import { config } from '@/core/config'

import { ErrorMonitoring } from './services/error-monitoring'

if (config.enableErrorReporting) {
  ErrorMonitoring.init()
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()
