import React from 'react'
import { Pill as MantinePill, PillProps } from '@mantine/core'

export const Pill = ({ classNames, children, ...props }: PillProps) => {
  return (
    <MantinePill classNames={classNames} {...props}>
      {children}
    </MantinePill>
  )
}
