import React, { PropsWithChildren } from 'react'

import { NotificationWSProvider } from '@/core/services/websocket'
import { Notifications } from '@/core/mantine-ui'

import { AUTO_CLOSE_DELAY } from './constants'

export const NotificationsWrapper = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Notifications autoClose={AUTO_CLOSE_DELAY} />
      <NotificationWSProvider>{children}</NotificationWSProvider>
    </>
  )
}
