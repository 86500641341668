import React, { forwardRef } from 'react'
import {
  ButtonProps as MantineButtonProps,
  ButtonVariant,
  ElementProps,
  Button as MantineButton,
} from '@mantine/core'

import styles from './Button.module.scss'

export interface ButtonProps
  extends MantineButtonProps,
    ElementProps<'button', keyof MantineButtonProps> {
  variant?:
    | ButtonVariant
    | 'danger'
    | 'danger-outline'
    | 'default-filled'
    | 'warning'
    | 'warning-outline'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'default', ...props }, ref) => {
    return (
      <MantineButton
        ref={ref}
        variant={variant}
        {...props}
        classNames={{
          root: styles.mantineButtonRoot,
          ...props.classNames,
        }}
      />
    )
  },
)

Button.displayName = 'Button' // Helpful for debugging with React DevTools
