import React from 'react'
import { Switch as MantineSwitch, SwitchProps } from '@mantine/core'

export const Switch: React.FC<SwitchProps> = ({
  checked = false,
  size = 'md',
  ...props
}) => {
  return <MantineSwitch size={size} checked={checked} {...props} />
}
