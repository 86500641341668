import React from 'react'

import { notifications as mantineNotifications } from '@/core/mantine-ui'
import { PushNotification } from '@/core/services/websocket/services'

import { getNotificationBackgroundColor, getNotificationIcon } from './helpers'
import { NotificationDescription, NotificationTitle } from './components'

export const notifications = {
  show: ({
    title,
    description,
    action_link,
    category,
    notification_type,
    autoclose,
  }: PushNotification) => {
    mantineNotifications.show({
      title: <NotificationTitle title={title} />,
      message: (
        <NotificationDescription
          description={description}
          actionLink={action_link}
        />
      ),
      color: getNotificationBackgroundColor(category),
      icon: getNotificationIcon(notification_type, category),
      autoClose: autoclose ?? undefined,
    })
  },
}
