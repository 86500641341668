import { CSSVariablesResolver } from '@mantine/core'

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-color-default-border': theme.colors.gray[2],
  },
  dark: {},
  light: {
    '--mantine-color-default-border': theme.colors.gray[2],
  },
})
