import React from 'react'

import { MaterialIcon } from '@/core/components/material-icon'
import {
  PushNotification,
  Notification,
} from '@/core/services/websocket/services'
import {
  DANGER_COLOR,
  PRIMARY_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
} from '@constants/styles/base-colors'

const NOTIFICATION_STATUS_ICONS = {
  success: 'check',
  error: 'exclamation',
  warning: 'exclamation',
  info: 'info_i',
}

export const getNotificationBackgroundColor = (
  category: Notification['category'],
) => {
  if (category === 'success') {
    return SUCCESS_COLOR
  } else if (category === 'error') {
    return DANGER_COLOR
  } else if (category === 'warning') {
    return WARNING_COLOR
  } else if (category === 'info') {
    return PRIMARY_COLOR
  }
}

export const getNotificationIcon = (
  notification_type: PushNotification['notification_type'],
  category: PushNotification['category'],
) => {
  if (notification_type !== 'default_with_icons') return null

  if (category === 'info') {
    return <MaterialIcon icon="info_i" />
  }

  return (
    <MaterialIcon
      icon={NOTIFICATION_STATUS_ICONS[category]}
      size={28}
      fontWeight={700}
    />
  )
}
